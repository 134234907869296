import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import "./bank-documents-tab.scss";
import { RegionParent } from "../../models/countries-colors";
import BackButton from "../back-button";
import Selector from "../selector";
import PrimaryButton from "../primary-button";
import NoDataDescription from "../no-data-description";
import Input from "../input";
import Api from "../../api/api";
import Loader from "../loader";
import { Document } from "../../types/types";
import BankDocumentList from "./components/bank-document-list";

interface BankDocumentsTabProps {
  handleClickBack: () => void;
  //
  setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>;
  mapRef: any;
  popup: any;
  setIsShowElectionPoints: Dispatch<(prevState: boolean) => undefined>;
};

const documentsTypeList = [
  {value: "Все документы", name: "Все документы"},
  {value: "Отмена выборов", name: "Отмена выборов"},
  {value: "Нарушения", name: "Нарушения"},
];

const regionsList = [
  { name: 'Россия', value: 'Россия' },
  ...Object.keys(RegionParent).map((item) => ({ name: item, value: item }))
]

export const BankDocumentsTab: FC<BankDocumentsTabProps> = ({ handleClickBack, mapRef, popup, setIsLoadingElectionPoints, setIsShowElectionPoints }) => {
  const api = new Api();
  const [documentsType, setDocumentsType] = useState("Все документы");
  const [region, setRegion] = useState('Россия');
  const [currentDocuments, setCurrentDocuments] = useState<Document[]>([]);
  const [totalDocuments, setTotalDocuments] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [pageDocuments, setPageDocuments] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const handleChangeDocumentsType = (value: string) => {
    setDocumentsType(value);
  };

  const handleChangeRegion = (value: string) => {
    setRegion(value);
  };

  const handleClickSearch = async () => {
    setIsLoading(true);
    const res = await api.getAllDocuments(pageDocuments);
    setCurrentDocuments(res.news);
    setTotalDocuments(res.total);
    setIsLoading(false);
  };

  const handleChangeDocumentsPage = async (_event: ChangeEvent<unknown>, value: number) => {
    setIsLoading(true);
    setPageDocuments(value);
    const res = await api.getAllDocuments(value);
    setCurrentDocuments(res.news);
    setTotalDocuments(res.total);
    setIsLoading(false);
  };

  const ref = useRef();

  return (
    <>
      <div className="title-wrapper">
        <h1 className="selector-module-title">
          Банк документов
        </h1>
        <BackButton className="back-btn-left" onClick={handleClickBack} />
      </div>
      <h2 className="selector-module-description">
        Это страница банка документов. Здесь содержатся все документы, изданные избирательными комиссиями Московской области. 
      </h2>
      <div className="bank-document-module-selector-container">
        <Selector onChange={handleChangeDocumentsType} value={documentsType} menuList={documentsTypeList} />
        <Selector onChange={handleChangeRegion} value={region} menuList={regionsList} />
        <Input value={searchValue} setValue={setSearchValue} />
        
        <PrimaryButton disabled={documentsType === "" || region === ""} onClick={handleClickSearch}>Показать</PrimaryButton>
      </div>
      {isLoading && (
        <div className="loader-container mt-150">
          <Loader ContainerRef={ref.current}  />
        </div>
      )}
      {!isLoading && currentDocuments?.length === 0 && (
        <NoDataDescription />
      )}
      {totalDocuments && currentDocuments?.length > 0 && (
        <div style={{ position: "relative" }}>
          <BankDocumentList
            pageDocuments={pageDocuments}
            currentDocuments={currentDocuments}
            handleChangeDocumentsPage={handleChangeDocumentsPage}
            total={totalDocuments}
            isLoading={isLoading}
            setIsLoadingElectionPoints={setIsLoadingElectionPoints}
            mapRef={mapRef}
            popup={popup}
            setIsShowElectionPoints={setIsShowElectionPoints}
            memberName={searchValue}
          />
        </div>
      )}
    </>
  );
};
