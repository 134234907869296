import "./boundary-map-controllers.scss";
import React, {
  Dispatch,
  FC,
  SetStateAction,
} from "react";
import { ElectionModuleMode } from "../../types/types";
import BackButton from "../back-button";

interface BoundaryMapControllersProps {
  setElectionModuleMode: Dispatch<SetStateAction<ElectionModuleMode>>;
  onChangeSingleMandateConstituencies: () => void;
  checkedSingleMandateConstituencies: boolean;
  //
  onChangeMunicipalConstituencies: () => void;
  checkedMunicipalConstituencies: boolean;
  handleClickSwitchLayer: () => void;
}

export const BoundaryMapControllers: FC<
  BoundaryMapControllersProps
> = ({ checkedMunicipalConstituencies, checkedSingleMandateConstituencies, onChangeMunicipalConstituencies, onChangeSingleMandateConstituencies, setElectionModuleMode, handleClickSwitchLayer }) => {

  const handleClickBack = () => {
    // props.hideElectionsPoints();
    // props.setElectionModuleMode("main");
    setElectionModuleMode("main");
    handleClickSwitchLayer();
  };

  return (
    <>
      <BackButton
        className="election-commission-back-btn"
        onClick={handleClickBack}
      />
      <div className="election-commission-controllers-container">
        <div className="election-commission-inner-container">
          <p className="election-commission-inner-text text-center">
            Карта границ избирательного устройства
          </p>
        </div>
        <div className="election-commission-inner-container">
          <div className="input-inner-container">
            <p className="election-commission-inner-text">Одномандатные избирательные округа</p>
            <input
              onChange={onChangeSingleMandateConstituencies}
              checked={checkedSingleMandateConstituencies}
              type={"checkbox"}
            />
          </div>
          <div className="input-inner-container">
            <p className="election-commission-inner-text">Муниципальные избирательные округа</p>
            <input
              onChange={onChangeMunicipalConstituencies}
              checked={checkedMunicipalConstituencies}
              type={"checkbox"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
