import React, { FC, PropsWithChildren, useMemo, useState } from "react";
import { Election, ElectionEventData, ElectionModuleMode } from "../../types/types";
import { getNameByLevel } from "../../models/utils";
import { MapContext } from "./context";

export const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [showType, setShowType] = useState(false);
  const [tempMode, setTempMode] = useState(false);
  const [popupMode, setPopupMode] = useState(false);
  const [popupsData, setPopupsData] = useState(63);
  const [renderKey, setRenderKey] = useState(0);
  const [mapRenderKey, setMapRenderKey] = useState(0);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [openMenuState, setOpenMenuState] = useState([false, false, false]);
  const [name, setName] = useState('Центральный федеральный округ');
  const [region, setRegion] = useState('');
  const [compareMode, setCompareMode] = useState(false);
  const [compareName, setCompareName] = useState("Уральский федеральный округ");
  const [compareRegion, setCompareRegion] = useState("");
  const [level, setLevel] = useState(1);
  const [lastName, setLastName] = useState('');
  const [lastRegion, setLastRegion] = useState('');
  const [lastCompareName, setLastCompareName] = useState('');
  const [lastCompareRegion, setLastCompareRegion] = useState('');
  const [temperature, setTemperature] = useState(null);
  const [temperatureParam, setTemperatureParam] = useState(null);
  const [tempOpenMenu, setTempOpenMenu] = useState<string | number>(0);
  const [allElection, setAllElection] = useState<Election[]>([]);
  const [selectedElectionEvent, setSelectedElectionEvent] = useState<string>();
  const [selectedElectionEventData, setSelectedElectionEventData] = useState<ElectionEventData | null>(null);
  const [selectedElectionCandidate, setSelectedElectionCandidate] = useState<string | null>(null);
  const [isShowElectionPoints, setIsShowElectionPoints] = useState<boolean>();
  const [isLoadingElectionPoints, setIsLoadingElectionPoints] = useState(false);
  const [uiMode, setUiMode] = useState<"map" | "election-module">('map');
  const [electionModuleMode, setElectionModuleMode] = useState<ElectionModuleMode>("main");
  const [activeLayerId, setActiveLayerId] = useState(getNameByLevel(level, showType));
  const [isShowElectionsResultOnMap, setIsShowElectionsResultOnMap] = useState(false);
  const [openMenusData, setOpenMenusData] = useState([
      {
          name: 'Паспорт региона',
          data: {},
          change_temperature: setTemperature,
          change_temperature_param: setTemperatureParam,
      },
      {
          name: 'Социально-экономические показатели',
          data: {},
          change_temperature: setTemperature,
          change_temperature_param: setTemperatureParam,
      },
      {
          name: 'Избирательные показатели',
          data: {},
          change_temperature: setTemperature,
          change_temperature_param: setTemperatureParam,
      },
      // {
      //     name: 'Банк документов',
      //     data: {},
      //     change_temperature: setTemperature,
      //     change_temperature_param: setTemperatureParam,
      // },
  ]);

  const contextMemoized = useMemo(
    () => ({
      showType,
      tempMode,
      popupMode,
      popupsData,
      renderKey,
      mapRenderKey,
      closeSideMenu,
      openMenuState,
      name,
      region,
      compareMode,
      compareName,
      compareRegion,
      level,
      lastName,
      lastRegion,
      lastCompareName,
      lastCompareRegion,
      temperature,
      temperatureParam,
      tempOpenMenu,
      allElection,
      selectedElectionEvent,
      selectedElectionEventData,
      selectedElectionCandidate,
      isShowElectionPoints,
      isLoadingElectionPoints,
      uiMode,
      electionModuleMode,
      activeLayerId,
      isShowElectionsResultOnMap,
      openMenusData,
      setShowType,
      setTempMode,
      setPopupMode,
      setPopupsData,
      setRenderKey,
      setMapRenderKey,
      setCloseSideMenu,
      setOpenMenuState,
      setName,
      setRegion,
      setCompareMode,
      setCompareName,
      setCompareRegion,
      setLevel,
      setLastName,
      setLastRegion,
      setLastCompareName,
      setLastCompareRegion,
      setTemperature,
      setTemperatureParam,
      setTempOpenMenu,
      setAllElection,
      setSelectedElectionEvent,
      setSelectedElectionEventData,
      setSelectedElectionCandidate,
      setIsShowElectionPoints,
      setIsLoadingElectionPoints,
      setUiMode,
      setElectionModuleMode,
      setActiveLayerId,
      setIsShowElectionsResultOnMap,
      setOpenMenusData,
    }),
    [
      showType,
      tempMode,
      popupMode,
      popupsData,
      renderKey,
      mapRenderKey,
      closeSideMenu,
      openMenuState,
      name,
      region,
      compareMode,
      compareName,
      compareRegion,
      level,
      lastName,
      lastRegion,
      lastCompareName,
      lastCompareRegion,
      temperature,
      temperatureParam,
      tempOpenMenu,
      allElection,
      selectedElectionEvent,
      selectedElectionEventData,
      selectedElectionCandidate,
      isShowElectionPoints,
      isLoadingElectionPoints,
      uiMode,
      electionModuleMode,
      activeLayerId,
      isShowElectionsResultOnMap,
      openMenusData,
    ]
  );


  return (
    <MapContext.Provider value={contextMemoized}>
      {children}
    </MapContext.Provider>
  );
}
