import { createContext, Dispatch, SetStateAction } from "react";
import { Election, ElectionEventData, ElectionModuleMode, UiMode } from "../../types/types";


interface MapContextProps {
  showType: boolean,
  tempMode: boolean,
  popupMode: boolean,
  popupsData: number,
  renderKey: number,
  mapRenderKey: number,
  closeSideMenu: boolean,
  openMenuState: boolean[],
  name: string,
  region: string,
  compareMode: boolean,
  compareName: string,
  compareRegion: string,
  level: number,
  lastName: string,
  lastRegion: string,
  lastCompareName: string,
  lastCompareRegion: string,
  temperature: null, // add types
  temperatureParam: null, // add types
  tempOpenMenu: number | string,
  allElection: Election[],
  selectedElectionEvent: string,
  selectedElectionEventData: ElectionEventData | null,
  selectedElectionCandidate: string | null,
  isShowElectionPoints: boolean,
  isLoadingElectionPoints: boolean,
  uiMode: UiMode,
  electionModuleMode: ElectionModuleMode,
  activeLayerId: string,
  isShowElectionsResultOnMap: boolean,
  openMenusData:
    {
      name: string,
      // data: {},
      data: any,
      change_temperature: Dispatch<any>;
      change_temperature_param: Dispatch<any>;
  }[],
  setShowType: Dispatch<SetStateAction<boolean>>,
  setTempMode: Dispatch<SetStateAction<boolean>>,
  setPopupMode: Dispatch<SetStateAction<boolean>>,
  setPopupsData: Dispatch<SetStateAction<number>>,
  setRenderKey: Dispatch<SetStateAction<number>>,
  setMapRenderKey: Dispatch<SetStateAction<number>>,
  setCloseSideMenu: Dispatch<SetStateAction<boolean>>,
  setOpenMenuState: Dispatch<SetStateAction<boolean[]>>,
  setName: Dispatch<SetStateAction<string>>,
  setRegion: Dispatch<SetStateAction<string>>,
  setCompareMode: Dispatch<SetStateAction<boolean>>,
  setCompareName: Dispatch<SetStateAction<string>>,
  setCompareRegion: Dispatch<SetStateAction<string>>,
  setLevel: Dispatch<SetStateAction<number>>,
  setLastName: Dispatch<SetStateAction<string>>,
  setLastRegion: Dispatch<SetStateAction<string>>,
  setLastCompareName: Dispatch<SetStateAction<string>>,
  setLastCompareRegion: Dispatch<SetStateAction<string>>,
  setTemperature: Dispatch<SetStateAction<null | { [key: string]: string }[]>>, // add types 
  setTemperatureParam: Dispatch<SetStateAction<string | null>>, // add types
  setTempOpenMenu: Dispatch<SetStateAction<string | number>>,
  setAllElection: Dispatch<SetStateAction<Election[]>>,
  setSelectedElectionEvent: Dispatch<SetStateAction<string>>,
  setSelectedElectionEventData: Dispatch<SetStateAction<ElectionEventData | null>>,
  setSelectedElectionCandidate: Dispatch<SetStateAction<string | null>>,
  setIsShowElectionPoints: Dispatch<SetStateAction<boolean>>,
  setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>,
  setUiMode: Dispatch<SetStateAction<UiMode>>,
  setElectionModuleMode: Dispatch<SetStateAction<ElectionModuleMode>>,
  setActiveLayerId: Dispatch<SetStateAction<string>>,
  setIsShowElectionsResultOnMap: Dispatch<SetStateAction<boolean>>,
  setOpenMenusData: Dispatch<SetStateAction<{
    name: string;
    data: {};
    change_temperature: React.Dispatch<any>;
    change_temperature_param: React.Dispatch<any>;
  }[]>>,
}


export const MapContext = createContext<MapContextProps>({
  showType: false,
  tempMode: false,
  popupMode: false,
  popupsData: 63,
  renderKey: 0,
  mapRenderKey: 0,
  closeSideMenu: false,
  openMenuState: [false, false, false],
  name: 'Центральный федеральный округ',
  region: '',
  compareMode: false,
  compareName: "Уральский федеральный округ",
  compareRegion: "",
  level: 1,
  lastName: '',
  lastRegion: '',
  lastCompareName: '',
  lastCompareRegion: '',
  temperature: null,
  temperatureParam: null,
  tempOpenMenu: 0,
  allElection: [],
  selectedElectionEvent: undefined,
  selectedElectionEventData: null,
  selectedElectionCandidate: null,
  isShowElectionPoints: false,
  isLoadingElectionPoints: false,
  uiMode: "map",
  electionModuleMode: "main",
  activeLayerId: null,
  isShowElectionsResultOnMap: false,
  openMenusData: [
    {
      name: 'Паспорт региона',
      data: {},
      change_temperature: () => {},
      change_temperature_param: () => {},
  },
  {
      name: 'Социально-экономические показатели',
      data: {},
      change_temperature: () => {},
      change_temperature_param: () => {},
  },
  {
      name: 'Избирательные показатели',
      data: {},
      change_temperature: () => {},
      change_temperature_param: () => {},
  },
  ],
  setShowType: () => {},
  setTempMode: () => {},
  setPopupMode: () => {},
  setPopupsData: () => {},
  setRenderKey: () => {},
  setMapRenderKey: () => {},
  setCloseSideMenu: () => {},
  setOpenMenuState: () => {},
  setName: () => {},
  setRegion: () => {},
  setCompareMode: () => {},
  setCompareName: () => {},
  setCompareRegion: () => {},
  setLevel: () => {},
  setLastName: () => {},
  setLastRegion: () => {},
  setLastCompareName: () => {},
  setLastCompareRegion: () => {},
  setTemperature: () => {},
  setTemperatureParam: () => {},
  setTempOpenMenu: () => {},
  setAllElection: () => {},
  setSelectedElectionEvent: () => {},
  setSelectedElectionEventData: () => {},
  setSelectedElectionCandidate: () => {},
  setIsShowElectionPoints: () => {},
  setIsLoadingElectionPoints: () => {},
  setUiMode: () => {},
  setElectionModuleMode: () => {},
  setActiveLayerId: () => {},
  setIsShowElectionsResultOnMap: () => {},
  setOpenMenusData: () => {},
});
