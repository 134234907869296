import "./compare-mode-switch.scss";
import React, { Dispatch, FC, SetStateAction, useContext } from "react";
import { MapContext } from "../../helpers/context-provider/context";

interface CrossMenuProps {
  setIsCompareMode: Dispatch<SetStateAction<boolean>>;
  isCompareMode: boolean;
}

export const CompareModeSwitch: FC<CrossMenuProps> = ({
  isCompareMode,
  setIsCompareMode,
}) => {
  const { setCompareMode } = useContext(MapContext);

  const onClick = () => {
    const newMode = !isCompareMode;
    setCompareMode(newMode);
    setIsCompareMode(newMode);
  };

  return (
    <div onClick={onClick} className="CrossMenuContainer">
      <div className="CrossMenuContent">
        {[...Array(2)].map((_, index) => (
          <div key={index} className={`line ${isCompareMode ? "compare-line" : ""}`} />
        ))}
      </div>
      <p>
        {isCompareMode
          ? "Выйти из режима сравнения"
          : "Добавить территорию к сравнению"}
      </p>
    </div>
  );
};
