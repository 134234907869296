import React, { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import "./elections-tab.scss";
import { Election, ElectionEventData, ElectionEventRegionData } from "../../types/types";
import { RegionParent } from "../../models/countries-colors";
import BackButton from "../back-button";
import Selector from "../selector";
import PrimaryButton from "../primary-button";
import NoDataDescription from "../no-data-description";
import ElectionsModuleResult from "./components/elections-module-result";
import { MapContext } from "../../helpers/context-provider/context";
import Api from "../../api/api";

interface ElectionsProps {
  handleClickBack: () => void;
  mapRef: any;
  regionsData: any;
  popup: any;
  legend: ElectionEventRegionData[];
  setLegend: Dispatch<SetStateAction<ElectionEventRegionData[]>>;
}

const getYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - 2012 + 1 }, (_, index) => {
    const year = 2012 + index;
    return { name: year, value: year };
  });
};

const menuList = [
  { name: 'Выборы', value: '' },
  { name: 'Выборы в Государственную думу', value: 'Выборы в Государственную думу' },
  { name: 'Выборы Президента Российской Федерации', value: 'Выборы Президента Российской Федерации' },
  { name: 'Выборы глав субъектов Российской Федерации', value: 'Выборы глав субъектов Российской Федерации' },
  { name: 'Выборы в думу субъекта Российской Федерации', value: 'Выборы в думу субъекта Российской Федерации' },
];

const presidentElectionsYears = [
  { name: "Год", value: "" },
  { name: 2011, value: 2011 },
  { name: 2018, value: 2018 },
  { name: 2024, value: 2024 },
];

const stateDumaElectionsYears = [
  { name: "Год", value: "" },
  { name: 2011, value: 2011 },
  { name: 2016, value: 2016 },
  { name: 2021, value: 2021 },
];

const getListYears = (election: string) => {
  if (election === "Выборы Президента Российской Федерации") {
    return presidentElectionsYears;
  };

  if (election === "Выборы в Государственную думу") {
    return stateDumaElectionsYears;
  }
  return [{ name: 'Год', value: '' }, ...getYears()];
}

export const ElectionsTab: FC<ElectionsProps> = (props) => {
  const {
    setSelectedElectionEventData,
    // handleSelectElEvent,
    selectedElectionEventData,
  } = useContext(MapContext);
  const [election, setElection] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const api = new Api();

  const handleChangeElection = (value: string) => {
    setElection(value);
    setSelectedYear("");
    setSelectedRegion("");
    setSelectedElectionEventData(null);
  };

  const handleChangeSelectedYear = (value: string) => {
    setSelectedYear(value);
    setSelectedRegion("");
    setSelectedElectionEventData(null);
  };

  const handleChangeSelectedRegion = (value: string) => {
    setSelectedRegion(value);
  };

  const handleSelectElEvent = async (value: string) => {
    const response = await api.getElection(value);
    setSelectedElectionEventData(response)
}

  const years = getListYears(election);
  const regions = [{ name: 'Территория', value: '' }, ...Object.keys(RegionParent).map((item) => ({ name: item, value: item }))];

  const handleClickShowResult = () => {
    if (election === "Выборы в Государственную думу") {
      handleSelectElEvent(`Выборы в Государственную думу (${selectedYear})`);
    }
    if (election === "Выборы Президента Российской Федерации") {
      handleSelectElEvent(`Выборы Президента Российской Федерации ${selectedYear}`);
    }
  };

  return (
    <>
      <div className="title-wrapper">
        <h1 className="selector-module-title">
          Выборы
        </h1>
        <BackButton className="back-btn-left" onClick={props.handleClickBack} />
      </div>
      <h2 className="selector-module-description">
        Это страница выборов. Здесь вы можете выбрать необходимую вам статистику по выборам президента, в госдуму, глав субъекта и в думу субъекта.
      </h2>
      <div className="election-module-selector-container">
        <Selector onChange={handleChangeElection} value={election} menuList={menuList} />
        <Selector disabled={election === ""} className="election-main-selector-year" onChange={handleChangeSelectedYear} value={selectedYear} menuList={years} />
        <Selector disabled={selectedYear === ""} onChange={handleChangeSelectedRegion} value={selectedRegion} menuList={regions} />
        
        <PrimaryButton disabled={election === "" || selectedYear === "" || selectedRegion === ""} onClick={handleClickShowResult}>Показать</PrimaryButton>
      </div>
      {(selectedElectionEventData && election && selectedYear && selectedRegion) ? (
        <ElectionsModuleResult
          selectedRegion={selectedRegion}
          mapRef={props.mapRef}
          popup={props.popup}
          legend={props.legend}
          setLegend={props.setLegend}
          regionsData={props.regionsData}
        />
      ) : (
        <NoDataDescription />
      )}
    </>
  );
}
