import React, { useContext } from "react";
import { ExtraDataMenu, OpenMenu, SvgCross } from "../";
import Api from "../../api/api";
import './side-menu-content.scss'
import { popupData } from "../../models/popupData";
import { MapContext } from "../../helpers/context-provider/context";

interface SideMenuContentProps {
    mapRef: any;
    activeMapLayerId: any;
    popup: any;
    differenceData: any;
}

const SideMenuContent = (props: SideMenuContentProps) => {
    const state = useContext(MapContext);
    
    const render = () => {
        const changeInput = (index: number) => {
            state.setPopupsData(index);
        }
        
        const sideMenuContentStyle = {
            opacity: '0',
            display: 'none'
        };
        const sideMenuHeaderStyle = {
            opacity: '0',
            display: 'none'
        };
        const sideMenuButtonsContainerStyle = {
            opacity: '0',
            display: 'none'
        };
        const sideMenuHeaderContainerStyle = {
            justifyContent: 'flex-end'
        };
        const sideMenuContainerStyle = {
            minWidth: 'calc(32px * 2 + 40px)',
            maxWidth: 'calc(32px * 2 + 40px)'
        };

        const getMainContent = () => {
            if (state.tempMode && state.level === 2)
                return <>
                    <div className="chosen_region">
                        <p>Выбранная область:</p>
                        <p className="bold-blue-text">{ state.name }</p>
                        { state.name === undefined ? <p>выберите область</p> : null }
                    </div>
                    
                    <ExtraDataMenu
                        mapRef={ props.mapRef }
                        popup={props.popup}
                    />
                </>
            else if (state.tempMode)
                return <>
                    <div className="chosen_region">
                        <p>Выбранная область:</p>
                        <p className="bold-blue-text">{ state.name }</p>
                        { state.name === undefined ? <p>выберите область</p> : null }
                    </div>
                    
                    <p>Выберите уровень регионов чтобы построить тепловую карту</p>
                </>
            if (state.popupMode)
                return <>
                    <div
                        style={ { ...(state.closeSideMenu && sideMenuContentStyle) } }
                        className="flex-col gap-10">
                        
                        { !state.showType
                            ? <>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((state.popupsData & (1 << 0)) === (1 << 0)) }
                                           onChange={ () => changeInput(0) }></input>
                                    <p>{ popupData[0].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((state.popupsData & (1 << 1)) === (1 << 1)) }
                                           onChange={ () => changeInput(1) }></input>
                                    <p>{ popupData[1].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((state.popupsData & (1 << 2)) === (1 << 2)) }
                                           onChange={ () => changeInput(2) }></input>
                                    <p>{ popupData[2].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((state.popupsData & (1 << 3)) === (1 << 3)) }
                                           onChange={ () => changeInput(3) }></input>
                                    <p>{ popupData[3].ru }</p>
                                </div>
                                <div className="flex-row gap-20">
                                    <input type={ "checkbox" }
                                           checked={ ((state.popupsData & (1 << 4)) === (1 << 4)) }
                                           onChange={ () => changeInput(4) }></input>   
                                    <p>{ popupData[4].ru }</p>
                                </div>
                            </>
                            : <div className="flex-row gap-20">
                                <input type={ "checkbox" }
                                       checked={ ((state.popupsData & (1 << 0)) === (1 << 0)) }
                                       onChange={ () => changeInput(0) }></input>
                                <p>Численность избирателей</p>
                            </div>
                        }
                    
                    </div>
                </>
            return <>
                <div
                    style={ { ...(state.closeSideMenu && sideMenuContentStyle) } }
                    className="SideMenuContent__content"
                >
                    <div className="chosen_region">
                        <p>Выбранная область:</p>
                        <p>{ state.name }</p>
                        { state.name === undefined ? <p>выберите область</p> : null }
                        
                        {
                            state.compareMode ?
                                <div>
                                    <p>В сравнении с</p>
                                    <p className="light-blue">{ state.compareName }</p>
                                </div>
                                : null
                        }
                    </div>
                    
                    { state.openMenusData.map((data, index) => {
                        if (state.level === 3 && index === 2) return null;
                        if (state.level === 4 && (index === 0 || index === 1)) return null;
                        return <OpenMenu
                            mapRef={props.mapRef}
                            openMenuIndex={index}
                            key={ 'OpenMenu' + index }
                            name={ data.name }
                            data={ data.data }
                            differenceData={ props.differenceData }
                            popup={props.popup}
                        />
                    }) }
                    
                    { (state.level === 1 || state.level === 2)
                        ? <button className="SideMenuContent__button" onClick={ async () => {
                            const api = new Api();
                            if (state.compareMode)
                                window.open(`${ api.base_url }${ state.level === 1 ? 'district' : 'region' }/presentation/?name=${ state.name }&name2=${ state.compareName }`);
                            else
                                window.open(`${ api.base_url }${ state.level === 1 ? 'district' : 'region' }/presentation/?name=${ state.name }`)
                        } }>Скачать презентацию</button>
                        : null }
                </div>
            </>
        }
        
        return (
            <div
                style={{ ...(state.closeSideMenu && sideMenuContainerStyle)}}
                className="SideMenuContent"
            >
                <div
                    style={ { ...(state.closeSideMenu && sideMenuHeaderContainerStyle) } }
                    className="flex-row align-center just-space w-full float-right"
                >
                    <p
                        style={ { ...(state.closeSideMenu && sideMenuHeaderStyle) } }
                        className="SideMenuHeader"
                    >
                        Показатели
                    </p>
                    
                    <SvgCross />
                </div>
                
                {!state.compareMode ? (
                    <section 
                        style={ { ...(state.closeSideMenu && sideMenuButtonsContainerStyle) } }
                        className="radio-input"
                    >
                        <label>
                            <input
                                value="value-1"
                                name="value-radio"
                                id="value-1"
                                type="radio"
                                checked={!state.popupMode && !state.tempMode}
                                onChange={() => {
                                    state.setPopupMode(false);
                                    state.setTempMode(false);
                                    state.setIsShowElectionPoints(false);
                                }}
                            />
                            <span>Таблица</span>
                        </label>
                        <label>
                            <input
                                value="value-2"
                                name="value-radio"
                                id="value-2"
                                type="radio"
                                checked={state.tempMode}
                                onChange={() => {
                                    state.setPopupMode(false);
                                    state.setTempMode(true);
                                    state.setIsShowElectionPoints(false);
                                }}
                            />
                            <span>Тепловая карта</span>
                        </label>
                        <span className="selection"></span>
                    </section>
                ) : (null) }
                
                <div
                    id="SideMenuContent"
                    style={{ ...(state.closeSideMenu && sideMenuContentStyle)}}
                >
                    {getMainContent()}
                </div>
            </div>
        );
    }
    
    return render();
}

export default SideMenuContent;
