import React, { useContext } from 'react';
import { MapContext } from '../../helpers/context-provider/context';
import './svg-cross.scss';

export const SvgCross = () => {
  const state = useContext(MapContext);

  const handleClick = () => {
    state.setCloseSideMenu((prevValue) => !prevValue);
  };

  return (
    <div
      onClick={handleClick}
      className={`svg-cross-container ${state.closeSideMenu ? 'is-active' : ''}`}
    >
      <span className="svg-cross-line" />
      <span className="svg-cross-line" />
    </div>
  );
};
