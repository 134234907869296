import React, { useContext, useEffect, useRef } from "react";
import './app.scss';
import { MapProject, SideMenu } from "./components";
import Api from "./api/api";
import { addingLayerName, toggleBarsLayersIds } from "./models/utils";
import ElectionModule from "./components/election-module";
import ElectionCommissionControllers from "./components/election-commission-controllers";
import BackButton from "./components/back-button";
import { MapContext } from "./helpers/context-provider/context";

interface AppProps {
    all_data: any[];
}

const App = (props: AppProps) => {
    const {
        showType,
        tempMode,
        popupMode,
        popupsData,
        renderKey,
        mapRenderKey,
        name,
        compareMode,
        compareName,
        level,
        temperatureParam,
        uiMode,
        electionModuleMode,
        isShowElectionsResultOnMap,
        setTempMode,
        setPopupMode,
        setRenderKey,
        setMapRenderKey,
        setName,
        setRegion,
        setCompareName,
        setCompareRegion,
        setLevel,
        setTemperature,
        setAllElection,
        setIsShowElectionPoints,
        setUiMode,
        setElectionModuleMode,
        setActiveLayerId,
        setIsShowElectionsResultOnMap,
    } = useContext(MapContext);

    const popup = useRef(null);
    const electionPointPopup = useRef(null);
    const mapRef = useRef(null);
    const activeMapLayerId = useRef(null);
    const levelBeforeTempSwitch = useRef(null);
    const isFirstTempModeChange = useRef(true);
    const api = new Api();

    useEffect(() => {
        if (tempMode) {
            if (mapRef.current?.getLayer("places")) {
                mapRef.current.removeLayer("places");
                mapRef.current.removeSource("places");
            }
            if (mapRef.current.getLayer('places-tik')) {
                mapRef.current.removeLayer('places-tik');
                mapRef.current.removeSource('places-tik');
            }
            if (mapRef.current.getLayer('places-uik')) {
                mapRef.current.removeLayer('places-uik');
                mapRef.current.removeSource('places-uik');
            }
        }

        if (!isFirstTempModeChange.current) {
            if (!tempMode) {
                setLevel(levelBeforeTempSwitch.current);
                
                // removing the temp mode
                setTemperature(null);
                if (mapRef.current.getLayer('temperature')) {
                    mapRef.current.removeLayer('temperature');
                    mapRef.current.removeSource('temperature');
                }

                if (mapRef.current.getLayer('elections-results')) {
                    mapRef.current.removeLayer('elections-results');
                    mapRef.current.removeSource('elections-results');
                }

                if (mapRef.current.getLayer("places")) {
                    mapRef.current.removeLayer("places");
                    mapRef.current.removeSource("places");
                }
                if (mapRef.current.getLayer('places-tik')) {
                    mapRef.current.removeLayer('places-tik');
                    mapRef.current.removeSource('places-tik');
                }
                if (mapRef.current.getLayer('places-uik')) {
                    mapRef.current.removeLayer('places-uik');
                    mapRef.current.removeSource('places-uik');
                }
                
                // making previous layers visible (before switching to the temp mode)
                toggleBarsLayersIds.forEach((layerId) => {
                    if (activeMapLayerId.current === layerId) {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'visible');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'visible');
                    } else {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
                    }
                });
                
                if (activeMapLayerId.current === 'municipalities') {
                    mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'visible');
                    mapRef.current.setLayoutProperty('background-muns', 'visibility', 'visible');
                } else {
                    mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'none');
                    mapRef.current.setLayoutProperty('background-muns', 'visibility', 'none');
                }
                
                if (activeMapLayerId.current === 'municipalities' || activeMapLayerId.current === 'izbirkoms')
                    mapRef.current.setLayoutProperty('background-regions', 'visibility', 'visible');
                else
                    mapRef.current.setLayoutProperty('background-regions', 'visibility', 'none');
            } else {
                levelBeforeTempSwitch.current = level;
                setLevel(2);
                
                // setting none to all layers visibility
                toggleBarsLayersIds.forEach((layerId) => {
                    if ('regions' === layerId) {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'visible');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'visible');
                    } else {
                        mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
                        mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
                    }
                });
                
                mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'none');
                mapRef.current.setLayoutProperty('background-muns', 'visibility', 'none');
                mapRef.current.setLayoutProperty('background-regions', 'visibility', 'none');
                
                // clearing inside layers and filters to them
                for (const layers_group of addingLayerName)
                    for (const key in layers_group)
                        if (mapRef.current.getLayer(layers_group[key]))
                            mapRef.current.removeLayer(layers_group[key]);
                
                mapRef.current.setFilter('districts', null);
                mapRef.current.setFilter('regions', null);
            }
        }
        isFirstTempModeChange.current = false;
    }, [tempMode]);

    useEffect(() => {
        // @ts-ignore
        if (performance.memory) {
            // @ts-ignore
            const memory = performance.memory;
            if (memory.usedJSHeapSize / 1048576 > 2500 || memory.totalJSHeapSize / 1048576 > 2500) { // @ts-ignore
                window.location.assign(window.location.href);
            }
        }
        
    }, [mapRenderKey, renderKey]);

    useEffect(() => setRenderKey(renderKey ^ 1), [name, compareName, popupMode, popupsData, compareMode, temperatureParam]);

    useEffect(() => {
        if (mapRef.current === null) return;
        setMapRenderKey(mapRenderKey ^ 1);
    }, [popupsData, showType, popupMode]);

    useEffect(() => {
        const set = (resp: string) => {
            const data = JSON.parse(resp);
            let name_found = false, compare_name_found = false;
            for (const el of data) {
                if (el.name === name)
                    name_found = true;
                if (el.name === compareName)
                    compare_name_found = true;
            }
            
            if (!name_found)
                setName(data[0].name);
            if (!compare_name_found)
                setCompareName(data[0].name);
        }
        let _level = level;
        if (tempMode) _level = 2;
        
        switch (_level) {
            case (0):
                setName('Россия')
                setCompareName('Россия')
                break;
            case (1):
                api.getAllElection()
                    .then(res => setAllElection(res))
                    .catch(error => {
                        console.log(error);
                    });

                api.get_all_districts()
                    .then(resp => set(resp))
                    .catch(error => {
                        console.log(error);
                    });
                break;
            case (2):
                api.get_all_regions()
                    .then(resp => set(resp))
                    .catch(error => {
                        console.log(error);
                    });
                break;
            case (3):
                setName('Пушкино');
                setRegion('Московская область');
                setCompareName('Пушкино');
                setCompareRegion('Московская область');
                break;
            case(4):
                api.get_all_izbirkoms().then(resp => {
                    set(resp);
                });
                break;
        }
    }, [level]);

    const hideElectionsPoints = () => {
        if (popup.current) {
            mapRef.current.getCanvas().style.cursor = "";
            popup.current.remove();
            popup.current = null;
        }
        if (electionPointPopup.current) {
            mapRef.current.getCanvas().style.cursor = "";
            electionPointPopup.current.remove();
            electionPointPopup.current = null;
        }
        if (mapRef.current.getLayer("places")) {
            mapRef.current.removeLayer("places");
            mapRef.current.removeSource("places");
        }
        if (mapRef.current.getLayer('places-tik')) {
            mapRef.current.removeLayer('places-tik');
            mapRef.current.removeSource('places-tik');
        }
        if (mapRef.current.getLayer('places-uik')) {
            mapRef.current.removeLayer('places-uik');
            mapRef.current.removeSource('places-uik');
        }
        if (mapRef?.current?.getLayer("elections-results")) {
            mapRef.current.removeLayer("elections-results");
            mapRef.current.removeSource("elections-results");
        }
        if (mapRef.current) {
            toggleBarsLayersIds.forEach((layerId) => {
            if (activeMapLayerId.current === layerId) {
                mapRef.current.setLayoutProperty(
                    layerId,
                    "visibility",
                    "visible",
                );
                mapRef.current.setLayoutProperty(
                    layerId + "-outline",
                    "visibility",
                    "visible",
                );
            } else {
                mapRef.current.setLayoutProperty(layerId, "visibility", "none");
                mapRef.current.setLayoutProperty(
                    layerId + "-outline",
                    "visibility",
                    "none",
                );
            }
        });
    
        if (activeMapLayerId.current === "municipalities") {
            mapRef.current.setLayoutProperty(
                "background-regions-outline",
                "visibility",
                "visible",
            );
            mapRef.current.setLayoutProperty(
                "background-muns",
                "visibility",
                "visible",
            );
        } else {
            mapRef.current.setLayoutProperty(
                "background-regions-outline",
                "visibility",
                "none",
            );
            mapRef.current.setLayoutProperty(
                "background-muns",
                "visibility",
                "none",
            );
          }
    
          if (
            activeMapLayerId.current === "municipalities" ||
            activeMapLayerId.current === "izbirkoms"
          )
            mapRef.current.setLayoutProperty(
                "background-regions",
                "visibility",
                "visible",
            );
          else
            mapRef.current.setLayoutProperty(
                "background-regions",
                "visibility",
                "none",
            );
        }
    
        mapRef.current.setFilter('districts', null);
        mapRef.current.setFilter('regions', null);
        return;
    };

    const handleClickMapUI = () => {
        setUiMode('map');
        setElectionModuleMode('main');
        setActiveLayerId('regions');
        setIsShowElectionsResultOnMap(false);
        hideElectionsPoints();
        activeMapLayerId.current = 'regions';
        levelBeforeTempSwitch.current = 2;
        setLevel(2);
        
        // setting none to all layers visibility
        toggleBarsLayersIds.forEach((layerId) => {
            if ('regions' === layerId) {
                mapRef.current.setLayoutProperty(layerId, 'visibility', 'visible');
                mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'visible');
            } else {
                mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
                mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
            }
        });
        
        mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'none');
        mapRef.current.setLayoutProperty('background-muns', 'visibility', 'none');
        mapRef.current.setLayoutProperty('background-regions', 'visibility', 'none');
        
        // clearing inside layers and filters to them
        for (const layers_group of addingLayerName)
            for (const key in layers_group)
                if (mapRef.current.getLayer(layers_group[key]))
                    mapRef.current.removeLayer(layers_group[key]);
        
        mapRef.current.setFilter('districts', null);
        mapRef.current.setFilter('regions', null);
    };

    const handleClickSelectorModeUI = () => {
        setUiMode('election-module');
        setPopupMode(false);
        setTempMode(false);
        setIsShowElectionPoints(false);
    };

    const handleClickBack = () => {
        setIsShowElectionsResultOnMap(false);
    }

    return (
        <div className="main-container-wrapper">
            <div className="main-menu-toggler">
                <button onClick={handleClickMapUI} className={`main-menu-item ${uiMode === "map" ? "selected-menu-item" : ""}`}>Карта</button>
                <button onClick={handleClickSelectorModeUI} className={`main-menu-item ${uiMode === "election-module" ? "selected-menu-item" : ""}`}>Избирательный модуль</button>
                <div className={`selector-ui-backing ${uiMode === "map" ? "map-backing" : "selector-module-backing"}`} />
            </div>

            {isShowElectionsResultOnMap && <BackButton style={{ top: '37px' }} className="back-btn-left" onClick={handleClickBack} />}

            {uiMode === "election-module" && electionModuleMode === "commissions-tab" && (
                <ElectionCommissionControllers
                    hideElectionsPoints={hideElectionsPoints}
                    setElectionModuleMode={setElectionModuleMode}
                    mapRef={mapRef}
                    popup={popup}
                    electionPointPopup={electionPointPopup}
                    activeMapLayerId={activeMapLayerId}
                />
            )}

            {uiMode === "election-module" && (electionModuleMode !== "commissions-tab" && electionModuleMode !== "boundary-map-tab") && (
                <ElectionModule
                    mapRef={mapRef}
                    popup={popup}
                    regionsData={props.all_data === null ? null : props.all_data[2]}
                />
            )}

            <div  className="MainContainer">
                {(!isShowElectionsResultOnMap && electionModuleMode !== "commissions-tab" && electionModuleMode !== "boundary-map-tab") && (
                    <div className="side-menu-container" style={{ zIndex: 1000 }}>
                        <SideMenu
                            mapRef={ mapRef }
                            activeMapLayerId={ activeMapLayerId }
                            regionsData={ props.all_data }
                            popup={popup}
                        />
                    </div>
                )}

                <MapProject
                    mapRef={ mapRef }
                    activeMapLayerId={ activeMapLayerId }
                    allData={ props.all_data }
                    popup={popup}
                />
            </div>
        
        </div>
    );
}

export default App;
