import React, { Dispatch, SetStateAction } from "react";
import { LittleOpenMenu } from "../";
import './extra-data-menu.scss'
import { Election, ElectionEventData } from "../../types/types";
import ElectionController from "./components/election-controller";

interface ExtraDataProp {
    mapRef: any;
    regionsData: any;
    popup: any;
}

const ExtraDataMenu = (props: ExtraDataProp) => {
    return (
        <section style={ { display: 'flex', flexDirection: 'column', gap: '10px' } }>
            <p>Выберите показатель, чтобы просмотреть тепловую карту</p>
            <LittleOpenMenu
                mapRef={props.mapRef}
                regionsData={props.regionsData}
                popup={props.popup}
            />
            {/* <ElectionController {...props} /> */}
        </section>
    )
}

export default ExtraDataMenu;
