import React, { FC, useContext } from "react";
import { extraDataRowsNames } from "../../../../models/table-data-params";
import mapboxgl from 'mapbox-gl';
import Api from "../../../../api/api";
import { toggleBarsLayersIds } from "../../../../models/utils";
import { MapContext } from "../../../../helpers/context-provider/context";
import { Regions } from "../../../../models/countries-colors";

interface ItemProps {
    mapRef: any;
    categoryId: string;
    popup: any;
    item: {
        name: string;
        value: string;
        id: string;
    }
}

type RegionData = Record<string, number>;

const round = (number: number) => {
    return Math.round(number * 100) / 100;
}

const getHeatmapColor = (value: number, min: number, max: number) => {
    value = Math.max(min, Math.min(max, value));

    const normalized = (value - min) / (max - min);

    const r = Math.max(0, Math.min(255, 255 * (1 - normalized) * 2));
    const g = Math.max(0, Math.min(255, 255 * normalized * 2));
    const b = 0;
    
    return `rgb(${Math.round(r)}, ${Math.round(g)}, ${b})`;
}

const completeRegions = (existingRegions: RegionData) => {
    const regionsList = Object.entries(existingRegions);
    const existingRegionSet = new Set(regionsList.map(([region]) => region));
    const completedRegions = [...regionsList];
    
    Regions.forEach(region => {
      if (!existingRegionSet.has(region)) {
        completedRegions.push([region, 0]);
      }
    });

    return Object.fromEntries(completedRegions);
}

const setTemperature = (data: RegionData) => {
    const values = Object.values(data);
    const round = values.map((value) => Math.round((value - 100) * 100) / 100);
    const min = Math.min(...round);
    const max = Math.max(...round);
    const regions = Object.keys(data);

    const geoData = regions.map((region) => {
        const currentValue = Number(data[region]);
        const color = getHeatmapColor(Math.round((currentValue - 100) * 100) / 100, min, max);
        return [region, color];
    });

    return geoData;
}

export const Item: FC<ItemProps> = (props) => {
    const state = useContext(MapContext);
    let radioDivChosenStyle = {
        background: '#2a5cdc',
    }

    const getTemperature = async (name: string) => {
        if (!state.tempMode) return;
        const [cat, param] = getCatParam(name);
        const api = new Api();
        const response = await api.get_region_temperature(cat, param);
        const regionTemperatureData = JSON.parse(response);

        const completedRegions = completeRegions(regionTemperatureData);

        const geojsonData = setTemperature(completedRegions);

        if (props.mapRef.current.getLayer('temperature')) {
            props.mapRef.current.removeLayer('temperature');
            // props.mapRef.current.removeSource('temperature');
            // props.mapRef.current.removeSource('region');
        }
        if (props.mapRef.current.getLayer('places-tik')) {
            props.mapRef.current.removeLayer('places-tik');
            props.mapRef.current.removeSource('places-tik');
        }
        if (props.mapRef.current.getLayer('places-uik')) {
            props.mapRef.current.removeLayer('places-uik');
            props.mapRef.current.removeSource('places-uik');
        }
        if (props?.mapRef?.current?.getLayer("places")) {
            props.mapRef.current.removeLayer("places");
            props.mapRef.current.removeSource("places");
        }
        if (props?.mapRef?.current?.getLayer("elections-results")) {
            props.mapRef.current.removeLayer("elections-results");
            // props.mapRef.current.removeSource("elections-results");
        }
        toggleBarsLayersIds.forEach((layerId) => {
            props.mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
            props.mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
        });
        props.mapRef.current.setLayoutProperty('regions-outline', 'visibility', 'none');

        const matchArray = geojsonData.reduce((acc, [state, color]) => {
            acc.push(state, color);
            return acc;
        }, []);

        props.mapRef.current.addLayer({
            id: 'temperature',
            type: 'fill',
            source: 'regions',
            paint: {
              'fill-color': [
                'match',
                ['get', 'name'],
                ...matchArray,
                '#333333'
              ],
              'fill-opacity': 0.5
            }
          });

        props.mapRef.current.on('mousemove', 'temperature', (e) => {
            const currentValue = ParseElCount(round(regionTemperatureData[e.features[0].properties.name]), props.item.value);
            if (props.popup.current != null) {
              props.popup.current.setLngLat(e.lngLat);
              props.popup.current.setHTML(`<h3>${e.features[0].properties.name} </br> ${props.item.name}: ${currentValue}</h3>`);
            }
          })
      
          props.mapRef.current.on('mouseenter', 'temperature', (e) => {
            props.mapRef.current.getCanvas().style.cursor = 'pointer';
            if (props.popup.current === null) {
                props.popup.current = new mapboxgl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                })
                    .setLngLat(e.lngLat)
                    .setHTML(e.features[0].properties.name)
                    .addTo(props.mapRef.current);
              }
          });

        state.setTemperature(regionTemperatureData);
        state.setTemperatureParam(name)
  }

    const ParseElCount = (count?: number, value?: string) => {
        if (isNaN(count)) return 0;
        if (!count) return 0;
        if (value === '%') {
            return count === 0 ? `${count}%` : `${ Math.round((count - 100) * 100) / 100 }%`;
        }
        return `${count} ${value}`;
    }

    const getCatParam = (name: string) => {
        for (const cat in extraDataRowsNames)
            for (const param in extraDataRowsNames[cat]) {
                if (extraDataRowsNames[cat][param].name === name) {
                    return [cat, param];
                }
            }
    }

    return (
        <div className="lmb-content">
            <div
                className="lbm-div"
                onClick={() => {
                    getTemperature(props.item.name);
                }}
            >
                { !state.tempMode ? null :
                    <div className="radio-div">
                        <span style={ { ...(state.temperatureParam === props.item.name && radioDivChosenStyle) } }/>
                    </div>
                }
                <p className={ "lbm-line" }>
                    { props.item.name }:{' '}
                    <span style={{ 'cursor': 'pointer' }}>
                        {`${ParseElCount(round(state.openMenusData[1].data?.extra?.[props.categoryId]?.[props.item.id]), props.item.value)}`}
                    </span>
                </p>
            </div>
        </div>
    );
};
