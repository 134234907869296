import React, { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import './election-module.scss';
import { ReactSVG } from "react-svg";
import { Election, ElectionEventData, ElectionEventRegionColors, ElectionEventRegionData, ElectionModuleMode } from "../../types/types";
import ElectionsTab from "../elections-tab";
import BankDocumentsTab from "../bank-documents-tab";
import BackButton from "../back-button";
import { MapContext } from "../../helpers/context-provider/context";

interface MenuList {
  id: number,
  title: string,
  description: string,
  image: string,
  type: ElectionModuleMode,
};

const menuList: MenuList[] = [
  {
    id: 0,
    title: "Выборы",
    description: "Информация о выборах президента, в городскую думу, а также выборах глав субъектов",
    image: "elections",
    type: "elections-tab",
  },
  {
    id: 1,
    title: "Карта границ",
    description: "Карта границ избирательного устройства РФ с внутренним делением ",
    image: "map",
    type: "boundary-map-tab",
  },
  {
    id: 2,
    title: "Банк документов",
    description: "Все документы избирательных комиссий МО с возможностью фильтрации и поиска",
    image: "documents",
    type: "documents-tab",
  },
  {
    id: 3,
    title: "ТИК/УИК",
    description: "Местоположение ТИК/УИК на карте, а также дополнительная информация о комиссии",
    image: "commissions",
    type: "commissions-tab",
  },
];

interface SelectorModuleProps {
  mapRef: any;
  popup: any;
  regionsData: any;
};

export const ElectionModule: FC<SelectorModuleProps> = (props) => {
  const {
    setElectionModuleMode,
    isShowElectionsResultOnMap,
    setIsShowElectionsResultOnMap,
    selectedElectionEventData,
    electionModuleMode,
    setIsLoadingElectionPoints,
    setIsShowElectionPoints
  } = useContext(MapContext);

  const [legend, setLegend] = useState<ElectionEventRegionColors[]>();

  const handleClickBack = () => {
    setElectionModuleMode("main");
  };

  if (isShowElectionsResultOnMap) {
    return (
      <>
      <BackButton style={{ top: '37px' }} className="back-btn-left" onClick={() => setIsShowElectionsResultOnMap(false)} />
      {legend.length > 0 && (
        <div className="election-commission-controllers-container">
          <div className="election-commission-inner-container">
            <p className="election-commission-inner-text text-center">{selectedElectionEventData.name}</p>
          </div>
          <div
            style={{
              padding: '12px',
              borderRadius: '6px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                listStyle: 'none',
              }}
            >
              {legend.map((item) => (
                <li
                  key={item.name}
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    fontSize: '13px',
                    fontWeight: '400',
                    lineHeight: '15.73px',
                    color: '#333333',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: '10px',
                      height: '10px',
                      borderRadius: '2px',
                      border: '1px solid #000000'
                    }}
                  />
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
    );
  };

  return (
    <div className="selector-module-container">
      {electionModuleMode === "main" && (
        <>
          <h1 className="selector-module-title">
            Избирательный модуль
          </h1>
          <h2 className="selector-module-description">
            Вы находитесь в избирательном модуле проекта всяроссия.рф
            Ниже вы можете найти статистику о различных выборах, подробную информацию об избирательных комиссиях, а также ознакомиться с картой избирательного устройства РФ  
          </h2>
          <ul className="selector-module-list-container">
            {menuList.map((item) => (
              <li onClick={() => setElectionModuleMode(item.type)} className="selector-module-list-item" key={item.id}>
                <h3 className="selector-module-list-item-title">{item.title}</h3>
                <p className="selector-module-list-item-description">{item.description}</p>
                <div className="list-item-icon-wrapper">
                  <ReactSVG src={`/images/${item.image}.svg`} height="152px" width="152px" />
                </div>
              </li>
            ))}
          </ul>
          <img alt="map" width={1440} height={789} className="image-map" src="/images/map.png" />
        </>
      )}
      {electionModuleMode === "elections-tab" && (
        <ElectionsTab
          mapRef={props.mapRef}
          popup={props.popup}
          handleClickBack={handleClickBack}
          legend={legend}
          setLegend={setLegend}
          regionsData={props.regionsData}
        />
      )}
      {electionModuleMode === "documents-tab" && (
        <BankDocumentsTab
          handleClickBack={handleClickBack}
          setIsLoadingElectionPoints={setIsLoadingElectionPoints}
          mapRef={props.mapRef}
          popup={props.popup}
          setIsShowElectionPoints={setIsShowElectionPoints}
        />
      )}
    </div>
  );
};
