import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Api from "../../api/api";
import './side-menu.scss'
import SideMenuContent from "../side-menu-content";
import { MapContext } from "../../helpers/context-provider/context";

interface SideMenuProps {
    mapRef: any;
    activeMapLayerId: any;
    popup: any;
}

const SideMenu = (props: SideMenuProps) => {
    const state = useContext(MapContext);
    useEffect(() => {
        async function SubscribeAPI() {
            let resp: string = '';
            let level = state.level;
            if (state.tempMode) level = 2;
            switch (level) {
                case (0):
                    resp = await api_service.get_country();
                    GetDataFromApi(resp);
                    break;
                case(1):
                    // resp = await api_service.get_district(state.name);
                    // if (resp[0] === '<') {
                    //     state.setName(state.lastName);
                    //     state.setRegion(state.lastRegion);
                    // } else {
                    //     GetDataFromApi(resp);
                    //     if (state.compareName !== undefined && state.compareMode)
                    //         resp = await api_service.get_district(state.compareName);
                    //         if (resp[0] === '<') {
                    //             state.setCompareName(state.lastCompareName);
                    //             state.setCompareRegion(state.lastCompareRegion);
                    //         } else
                    //             GetDifferenceRegionData(resp);
                    // }
                    break;
                case(2):
                    // resp = await api_service.get_region(state.name);
                    // if (resp[0] === '<') {
                    //     state.setName(state.lastName);
                    //     state.setRegion(state.lastRegion);
                    // } else {
                    //     GetDataFromApi(resp);
                    //     if (state.compareName !== undefined && state.compareMode) {
                    //         resp = await api_service.get_region(state.compareName);
                    //     }
                    //     if (resp[0] === '<') {
                    //         state.setCompareName(state.lastCompareName);
                    //         state.setCompareRegion(state.lastCompareRegion);
                            
                    //     } else {
                    //         GetDifferenceRegionData(resp);
                    //     }
                    // }
                    break;
                case(3):
                    resp = await api_service.get_municipality(state.name, state.region);
                    if (resp[0] === '<') {
                        state.setName(state.lastName);
                        state.setRegion(state.lastRegion);
                    } else {
                        GetDataFromApi(resp);
                        if (state.compareName !== undefined && state.compareMode)
                            resp = await api_service.get_municipality(state.compareName, state.compareRegion)
                            if (resp[0] === '<') {
                                state.setCompareName(state.lastCompareName);
                                state.setCompareRegion(state.lastCompareRegion);

                            } else
                                GetDifferenceRegionData(resp);
                    }
                    break;
                case(4):
                    resp = await api_service.get_izbirkom(state.name);
                    if (resp[0] === '<') {
                        state.setName(state.lastName);
                        state.setRegion(state.lastRegion);
                    } else {
                        GetDataFromApi(resp);
                        if (state.compareName !== undefined && state.compareMode)
                            resp = await api_service.get_izbirkom(state.compareName);
                            if (resp[0] === '<') {
                                state.setCompareName(state.lastCompareName);
                                state.setCompareRegion(state.lastCompareRegion);

                            } else
                                GetDifferenceRegionData(resp);
                    }
                    break;
            }
        }

        SubscribeAPI().then().catch(err => console.log(err));
    }, []);

    useEffect(() => {
        const getDataDistrict = async () => {
            let resp = await api_service.get_district(state.name);
            if (resp[0] === '<') {
                state.setName(state.lastName);
                state.setRegion(state.lastRegion);
            } else {
                GetDataFromApi(resp);
                if (state.compareName !== undefined && state.compareMode)
                    resp = await api_service.get_district(state.compareName);
                    if (resp[0] === '<') {
                        state.setCompareName(state.lastCompareName);
                        state.setCompareRegion(state.lastCompareRegion);
                    } else
                        GetDifferenceRegionData(resp);
            }
        }
        if (state.level === 1) {
            getDataDistrict();
        }

    }, [state.compareMode, state.name, state.compareName]);

    useEffect(() => {
        const getDataRegion = async () => {
            let resp = await api_service.get_region(state.name);
            if (resp[0] === '<') {
                state.setName(state.lastName);
                state.setRegion(state.lastRegion);
            } else {
                GetDataFromApi(resp);
                if (state.compareName !== undefined && state.compareMode) {
                    resp = await api_service.get_region(state.compareName);
                }
                if (resp[0] === '<') {
                    state.setCompareName(state.lastCompareName);
                    state.setCompareRegion(state.lastCompareRegion);
                    
                } else {
                    GetDifferenceRegionData(resp);
                }
            }
        }
        if (state.level === 2) {
            getDataRegion();
        }

    }, [state.name, state.compareName]);


    useEffect(() => {
        const void_variable = {};
        if (state.openMenusData[0].data === void_variable) return;
        ReloadComponents();
    }, [state.openMenusData]);

    const [differenceData, setDifferenceData] = useState({});
    let api_service: Api = new Api();

    const [reloadKey, setReloadKeys] = useState(0);
    
    const round = (number: any, _level: any = 0) => {
        if (number === "…" || _level > 5) return 0;
        if (isNaN(Number(number))) {
            let result = {};
            for (const key in number)
                result[key] = round(number[key], _level + 1);
            return result;
        } else
            return Math.round(number * 100) / 100;
    }
    
    const DivideRegionsData = (container: any, data: any, dataKey: string) => {
        const first = [
            'nazi',
            'square',
            'pol_vozr',
            'education',
            'people',
            'migration',
            'household',
            'birth',
            'crimes',
        ];
        const second = [
            'agriculture',
            'build',
            'trade',
            'service',
            'invest',
            'success_organizations',
            'outlay',
            'salary',
        ];
        
        if (dataKey === 'nazi' || dataKey === 'education' || dataKey === 'pol_vozr') {
            container[0].data[dataKey] = data[dataKey];
            return;
        }
        if (dataKey === 'extra') {
            container[1].data[dataKey] = data[dataKey];
            return;
        }
        if (dataKey === 'izbirkom') {
            container[2].data['count_izbir'] = round(data['izbirkom']['count_izbir']);
            container[2].data['total_izbir_people'] = round(data['izbirkom']['total_izbir_people']);
            if (data['izbirkom']['party'] !== undefined)
                container[2].data['party'] = data['izbirkom']['party'];
            if (data['izbirkom']['president_turnout'] !== undefined)
                container[2].data['president_turnout'] = round(data['izbirkom']['president_turnout']);
            if (data['izbirkom']['gosdum_turnout'] !== undefined)
                container[2].data['gosdum_turnout'] = round(data['izbirkom']['gosdum_turnout']);
            if (data['izbirkom']['guber_turnout'] !== undefined)
                container[2].data['guber_turnout'] = round(data['izbirkom']['guber_turnout']);
            if (data['izbirkom']['soviet_republic_turnout'] !== undefined)
                container[2].data['soviet_republic_turnout'] = round(data['izbirkom']['soviet_republic_turnout']);
            if (data['izbirkom']['oblast_dum_turnout'] !== undefined)
                container[2].data['oblast_dum_turnout'] = round(data['izbirkom']['oblast_dum_turnout']);
            if (data['izbirkom']['oblast_sobr_turnout'] !== undefined)
                container[2].data['oblast_sobr_turnout'] = round(data['izbirkom']['oblast_sobr_turnout']);
            if (data['izbirkom']['election_results'] !== undefined)
                container[2].data['election_results'] = data['izbirkom']['election_results'];
            
            return;
        }
        if (dataKey === 'failed_organizations') container[1].data['success_organizations'] += round(data['failed_organizations']);
        if (dataKey === 'revenues') container[1].data['outlay'] += round(data['revenues']);
        
        for (const string of first) {
            if (dataKey === string) {
                container[0].data[dataKey] = round(data[dataKey]);
                return;
            }
        }
        
        for (const string of second) {
            if (dataKey === string) {
                container[1].data[dataKey] = round(data[dataKey]);
                return;
            }
        }
    }
    
    const ParseRegionData = (data: any) => {
        let container = state.openMenusData;
        for (const dataKey in data) {
            DivideRegionsData(container, data, dataKey);
        }
        state.setOpenMenusData(container);
    }
    
    const ParseRegionDifferenceData = (data: any) => {
        let difference_data = round(data);
        difference_data['success_organizations'] += difference_data['failed_organizations']
        difference_data['outlay'] += difference_data['revenues'];

        return difference_data;
    }
    
    const GetDataFromApi = (resp: string) => {
        state.setLastName(state.name);
        state.setLastRegion(state.region);
        
        const data = JSON.parse(resp);
        ParseRegionData(data.data);
    }
    
    const GetDifferenceRegionData = (resp: string) => {
        state.setLastCompareName(state.compareName);
        state.setLastCompareRegion(state.compareRegion);

        const data = JSON.parse(resp);
        setDifferenceData(ParseRegionDifferenceData(data.data));
    }
    
    const ReloadComponents = () => {
        setReloadKeys(reloadKey ^ 1);
    }
    
    useEffect(() => setReloadKeys(reloadKey ^ 1), [differenceData]);
    
    return (
        <SideMenuContent
            mapRef={props.mapRef}
            popup={props.popup}
            activeMapLayerId={props.activeMapLayerId}
            differenceData={differenceData}
        />
    );
};

export default SideMenu;
