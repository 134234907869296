import React, { FC, Dispatch, SetStateAction, ChangeEvent } from "react";
import "./input.scss";

interface InputProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const Input: FC<InputProps> = ({ setValue, value }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <input placeholder="Поиск" className="main-input" value={value} onChange={handleChange} />
  );
};
