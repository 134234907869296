import React, { useContext } from "react";
import "./little-open-menu.scss";
import { extraData } from "../../models/table-data-params";
import Item from "./components/item";
import { MapContext } from "../../helpers/context-provider/context";

interface LittleOpenMenuProp {
    mapRef: any;
    regionsData: any;
    popup: any;
}

const LittleOpenMenu = (props: LittleOpenMenuProp) => {
    const state = useContext(MapContext);
    const currentYear = new Date().getFullYear();
    return (
        <>
            {extraData.map((category, index) => (
                <div key={category.id} className="little-moving-block">
                    <input type="radio"
                        name="lmb-checkbox"
                        id={ 'lmb-checkbox' + index + category.title }
                        checked={ state.tempOpenMenu === index }
                        onChange={() => state.setTempOpenMenu(index)}
                        style={{ height: '50px' }}
                    />
                    <label htmlFor={ 'lbm-checkbox' + index + category.title } className="lmb-header">
                        <p>{ category.title } на {currentYear} год</p>
                        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.05687 -4.25131e-05L15.365 7.54541L12.3676 7.54541L7.99898 1.97723L8.1655 2.03405L7.7443 2.03405L7.92063 1.97723L3.54215 7.54541L0.554621 7.54541L6.86274 -4.2705e-05L9.05687 -4.25131e-05Z"
                                fill="black"
                            />
                        </svg>
                    </label>
                    {category.items.map((item) => (
                        <Item
                            item={item}
                            key={item.id}
                            categoryId={category.id}
                            mapRef={props.mapRef}
                            regionsData={props.regionsData}
                            popup={props.popup}
                        /> 
                    ))}
                </div>
            ))}
        </>
    );
};

export default LittleOpenMenu;
